import { Gauge, Gear, List, ListChecks, Printer, ShoppingCartSimple, User, Users } from "@phosphor-icons/react"
import { lazy } from "react"

import i18n from "../config/locales"
import PageView from "../layouts/layoutComponents/pageView"
import NotFound from "../pages/404"
import { SuspenseWrapper } from "./lazy"

const { t } = i18n

const LoginLogger = SuspenseWrapper(lazy(() => import("../pages/logger/loginLogger")))
const DashBoard = SuspenseWrapper(lazy(() => import("../pages/dashboard/index")))
const StaffInfo = SuspenseWrapper(lazy(() => import("../pages/staff/staffInfo")))
const OrderPerformance = SuspenseWrapper(lazy(() => import("../pages/staff/OrderPerformance")))
const PackagePerformance = SuspenseWrapper(lazy(() => import("../pages/staff/PackagePerformance")))
const RoleInfo = SuspenseWrapper(lazy(() => import("../pages/staff/roleInfo")))
const WhiteList = SuspenseWrapper(lazy(() => import("../pages/setting/WhiteList")))
const AccountSetting = SuspenseWrapper(lazy(() => import("../pages/setting/AccountSetting")))
const OperationLogInfo = SuspenseWrapper(lazy(() => import("../pages/operationLog/OperationLogInfo")))
const SupplyInfo = SuspenseWrapper(lazy(() => import("../pages/supply/SupplyInfo")))
const CategoryInfo = SuspenseWrapper(lazy(() => import("../pages/category/CategoryInfo")))
const ProductInfo = SuspenseWrapper(lazy(() => import("../pages/product/productInfo")))
const ProductDetail = SuspenseWrapper(lazy(() => import("../pages/product/ProductDetail")))
const CanteenInfo = SuspenseWrapper(lazy(() => import("../pages/canteen/CanteenInfo")))
const PriceInfo = SuspenseWrapper(lazy(() => import("../pages/price/PriceInfo")))
const CanteenDetail = SuspenseWrapper(lazy(() => import("../pages/canteen/CanteenDetail")))
const TripInfo = SuspenseWrapper(lazy(() => import("../pages/trip/TripInfo")))
const OrderInfo = SuspenseWrapper(lazy(() => import("../pages/order/OrderInfo")))
const OrderDistribution = SuspenseWrapper(lazy(() => import("../pages/order/OrderDistribution")))
const OrderProductSale = SuspenseWrapper(lazy(() => import("../pages/order/OrderProductSale")))
const OrderDetail = SuspenseWrapper(lazy(() => import("../pages/order/OrderDetail")))
const OrderInvoice = SuspenseWrapper(lazy(() => import("../pages/order/OrderInvoice")))
const Statistic = SuspenseWrapper(lazy(() => import("../pages/data/Statistic")))
const StockInfo = SuspenseWrapper(lazy(() => import("../pages/stock/StockInfo")))
const ProductStockDetail = SuspenseWrapper(lazy(() => import("../pages/product/ProductStockDetail")))

const MarketInfo = SuspenseWrapper(lazy(() => import("../pages/market/MarketInfo")))

export const routes = [
  {
    path: "/dashboard",
    title: t("router.DASHBOARD"),
    element: <DashBoard />,
    icon: <Gauge />,
    affix: true,
    keepAlive: true,
    children: [{ title: t("router.DASHBOARD"), path: "/dashboard/console", element: <DashBoard />, keepAlive: true }],
  },
  {
    path: "/order",
    title: t("router.ORDER_MANAGE"),
    element: <PageView />,
    icon: <ListChecks />,
    children: [
      { path: "/order/orderInfo", title: t("router.ORDER_LIST"), element: <OrderInfo />, keepAlive: true },
      {
        path: "/order/orderDistribution",
        title: t("router.PACKAGE_OVERVIEW"),
        element: <OrderDistribution />,
        keepAlive: true,
      },
      {
        path: "/order/orderProductSale",
        title: t("router.PRODUCT_SALES"),
        element: <OrderProductSale />,
        keepAlive: true,
      },
      { path: "/order/orderDetail", title: "订单详情", element: <OrderDetail />, hidden: true },
      { path: "/order/orderInvoice", title: "发票详情", element: <OrderInvoice />, hidden: true },
    ],
  },
  {
    path: "/product",
    title: t("router.PRODUCT_MANAGE"),
    element: <PageView />,
    icon: <List />,
    children: [
      { path: "/product/productInfo", title: t("router.PRODUCT_LIST"), element: <ProductInfo />, keepAlive: true },
      { path: "/product/productDetail", title: "产品详情", element: <ProductDetail />, hidden: true },
      {
        path: "/product/categoryInfo",
        title: t("router.PRODUCT_CATEGORY"),
        element: <CategoryInfo />,
        keepAlive: true,
      },
      { path: "/product/productStock", title: t("router.PRODUCT_STOCK"), element: <StockInfo />, keepAlive: true },
      { path: "/product/productStockDetail", title: "产品库存详情", element: <ProductStockDetail />, hidden: true },
      { path: "/product/supplyInfo", title: t("router.WAREHOUSE_LIST"), element: <SupplyInfo />, keepAlive: true },
    ],
  },
  {
    path: "/canteen",
    title: t("router.CUSTOMER_MANAGE"),
    element: <PageView />,
    icon: <Users />,
    children: [
      { path: "/canteen/canteenInfo", title: t("router.CANTEEN_MANAGE"), element: <CanteenInfo />, keepAlive: true },
      { path: "/canteen/priceInfo", title: t("router.PRRICE_MANAGE"), element: <PriceInfo />, keepAlive: true },
      { path: "/canteen/canteenDetail", title: "餐厅详情", element: <CanteenDetail />, hidden: true },
      { path: "/canteen/tripInfo", title: t("router.TRIP_MANAGE"), element: <TripInfo />, keepAlive: true },
    ],
  },
  {
    path: "/market",
    title: t("router.MARKETING_MANAGE"),
    element: <PageView />,
    icon: <ShoppingCartSimple />,
    children: [
      { path: "/market/marketInfo", title: t("router.MARKETING_MANAGE"), element: <MarketInfo />, keepAlive: true },
    ],
  },
  {
    path: "/data",
    title: t("router.DATA_ANALYSIS"),
    element: <PageView />,
    icon: <List />,
    children: [{ path: "/data/Statistic", title: t("router.DATA_ANALYSIS"), element: <Statistic />, keepAlive: true }],
  },
  {
    path: "/staff",
    title: t("router.STAFF_MANAGE"),
    element: <PageView />,
    icon: <User />,
    children: [
      { path: "/staff/staffInfo", title: t("router.STAFF_LIST"), element: <StaffInfo />, keepAlive: true },
      { path: "/staff/roleInfo", title: t("router.ROLE_LIST"), element: <RoleInfo />, keepAlive: true },
      { path: "/staff/orderPerformance", title: t("下单绩效"), element: <OrderPerformance />, keepAlive: true },
      { path: "/staff/packagePerformance", title: t("配单绩效"), element: <PackagePerformance />, keepAlive: true },
    ],
  },
  {
    path: "/log",
    title: t("router.LOGGER_MANAGE"),
    element: <PageView />,
    icon: <Printer />,
    children: [
      {
        path: "/log/operationLog",
        title: t("router.LOGGER_OPERATION"),
        element: <OperationLogInfo />,
        keepAlive: true,
      },
      { path: "/log/loginLog", title: t("router.LOGGER_LOGIN"), element: <LoginLogger />, keepAlive: true },
    ],
  },
  {
    path: "/setting",
    title: t("router.SYSTEM_SETTING"),
    element: <PageView />,
    icon: <Gear />,
    children: [
      { path: "/setting/whiteList", title: t("router.WHITE_LIST"), element: <WhiteList />, keepAlive: true },
      { path: "/setting/account", title: t("router.ACCOUNT_SETTING"), element: <AccountSetting />, keepAlive: true },
    ],
  },
  { path: "*", title: "Not Found", hidden: true, element: <NotFound /> },
]

export const routesForRoleTree = routes.slice(0, routes.length - 1)
