export default {
  RESET: "重置",
  SEARCH: "搜索",
  STATUS: "状态",
  NAME: "名称",
  DESC: "描述",
  USERNAME: "用户名",
  PHONE: "手机号",
  PASSWORD: "密码",
  ACTION: "操作",
  EDIT: "编辑",
  ADD: "新增",
  DELETE: "删除",
  DETAIL: "详情",
  ROLE: "角色",
  TIME: "时间",
  REMARK: "备注",
  LOCATION: "地址",
  ACCOUNT: "账户",
  AVATAR: "头像",
  NICKNAME: "昵称",
  PROFILE: "个人资料",
  SAVE: "保存",
  RESET_PASSWORD: "重置密码",
  THEME_SETTING: "主题设置",
  LOG_OUT: "登出",
  SECURITY: "安全",
  SECURITY_SETTING: "安全设置",
  SITE_SETTING: "站点设置",
  SITE_TITLE: "网站标题",
  SITE_LOGO: "网站logo",
  OPEN: "打开",
  CLOSE: "关闭",
  DICT_SETTING: "字典设置",
  NEW: "新建",
  CREATED_AT: "创建时间",

  // order
  SUPPLY: "仓库",
  CANTEEN: "餐厅",
  DELIVERY_DATE: "配送日期",
  TRIP: "车次",
  ORDER_NO: "订单号",
  INVOICE_NO: "发票号",
  PRODUCT_COUNT: "产品数量",
  AMOUNT: "金额",
  ORDER_DETAIL: "订单详情",
  INVOICE_DETAIL: "发票详情",
  PRODUCT_NAME: "产品名称",
  PRICE: "价格",
  ORDER_QUANTITY: "下单数量",
  ORDER_REMARK: "下单备注",
  PACK_QUANTITY: "配单数量",
  PACK_REMARK: "配单备注",

  已送达订单_1: "已送达订单",
}
