import type { IGlobal } from "@packages/types"
import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

// Define a type for the slice state
export interface IGlobalState extends Partial<IGlobal> {}

// Define the initial state using that type
const initialState: IGlobalState = {
  siteLogo: "",
  siteTitle: "KF",
  siteDesc: "",
}

export const globalSlice = createSlice({
  name: "global",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setGlobal(state, action: PayloadAction<IGlobalState>) {
      const newState = { ...initialState, ...action.payload }
      for (const key in newState) {
        state[key] = newState[key]
      }
    },
  },
})

export const { setGlobal } = globalSlice.actions

const GlobalReducer = globalSlice.reducer
export default GlobalReducer
