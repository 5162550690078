export default {
  DASHBOARD: "Dashboard",
  STAFF_MANAGE: "Staff",
  STAFF_LIST: "Staff List",
  ROLE_MANAGE: "Role",
  ROLE_LIST: "Role List",
  LOGGER_MANAGE: "Logger",
  LOGGER_LOGIN: "Login Log",
  LOGGER_OPERATION: "Operation Log",
  SECURITY_MANAGE: "Security",
  IP_WHITELIST: "IP Whitelist",
  ACCOUNT_WHITELIST: "Account Whitelsit",
  SYSTEM_SETTING: "System",
  WHITE_LIST: "Whitelist",
  ACCOUNT_SETTING: "Account Setting",
  GLOBAl_SETTING: "Global Setting",

  // 订单管理 订单列表 配单概况 产品销量
  ORDER_MANAGE: "Order",
  ORDER_LIST: "Order List",
  PACKAGE_OVERVIEW: "Package Overview",
  PRODUCT_SALES: "Product Sales",

  // 产品管理 产品列表 产品分类 产品库存 产品库存 仓库列表
  PRODUCT_MANAGE: "Product",
  PRODUCT_LIST: "Product List",
  PRODUCT_CATEGORY: "Product Category",
  PRODUCT_STOCK: "Product Stock",
  WAREHOUSE_LIST: "Supply List",

  // 客户管理 餐厅管理 价格管理 车次管理
  CUSTOMER_MANAGE: "Customer",
  CANTEEN_MANAGE: "Canteen",
  PRRICE_MANAGE: "Price",
  TRIP_MANAGE: "Trip",

  // 营销管理
  MARKETING_MANAGE: "Marketing",

  // 数据分析
  DATA_ANALYSIS: "Data Analysis",
}
