export default {
  DASHBOARD: "仪表盘",
  STAFF_MANAGE: "员工管理",
  STAFF_LIST: "员工列表",
  ROLE_MANAGE: "角色管理",
  ROLE_LIST: "角色列表",
  LOGGER_MANAGE: "日志管理",
  LOGGER_LOGIN: "登录日志",
  LOGGER_OPERATION: "操作日志",
  SECURITY_MANAGE: "安全设置",
  IP_WHITELIST: "IP白名单",
  ACCOUNT_WHITELIST: "账号白名单",
  SYSTEM_SETTING: "系统设置",
  WHITE_LIST: "白名单",
  ACCOUNT_SETTING: "账户设置",
  GLOBAL_SETTING: "全局参数",

  // 订单管理 订单列表 配单概况 产品销量
  ORDER_MANAGE: "订单管理",
  ORDER_LIST: "订单列表",
  PACKAGE_OVERVIEW: "配单概况",
  PRODUCT_SALES: "产品销量",

  // 产品管理 产品列表 产品分类 产品库存 产品库存 仓库列表
  PRODUCT_MANAGE: "产品管理",
  PRODUCT_LIST: "产品列表",
  PRODUCT_CATEGORY: "产品分类",
  PRODUCT_STOCK: "产品库存",
  WAREHOUSE_LIST: "仓库列表",

  // 客户管理 餐厅管理 价格管理 车次管理
  CUSTOMER_MANAGE: "客户管理",
  CANTEEN_MANAGE: "餐厅管理",
  PRRICE_MANAGE: "价格管理",
  TRIP_MANAGE: "车次管理",

  // 营销管理
  MARKETING_MANAGE: "营销管理",

  // 数据分析
  DATA_ANALYSIS: "数据分析",
}
